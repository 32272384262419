<template>
  <div v-if="dataLoaded" class="grid grid-cols-12 manrope mb-36" style="/*background-color: #fffbef;*/ position:relative">
    <div class="logo-kg" style="">
      <router-link class="logo-link--" to="/" style="">
        <!-- <img src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/> -->
        <img v-if="$store.getters['app/locale'] === 'el'" src="../../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
        <img v-if="$store.getters['app/locale'] === 'en'" src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>
    </div>

    <div class="logo-container-abs" style="">
      <router-link class="" to="/">
          <!-- <img src="../../assets/svg/logo-en.svg" class="gp-logo"/> -->
          <img v-if="$store.getters['app/locale'] === 'el'" src="../../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
          <img v-if="$store.getters['app/locale'] === 'en'" src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
        </router-link>
    </div>
    
    <div class="logo-container" style="">
      <!--<router-link class="logo-link" to="/" style="">
        <img src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>-->
    </div>
    
    
    <div id="left-col" class="col-span-full sm:col-span-4 xl:col-span-6 py-6 sm:py-0 text-15 txt-lh-18">
      
    </div>

    <div id="right-col" class="col-span-full sm:col-start-5 sm:col-span-8 xl:col-span-6  xl:col-start-7 py-6 sm:py-0">

      <div class="col-span-full text-right close-btn-container">
        <a class="text-15 text-medium cursor-pointer" @click="$router.go(-1)">
          {{$t('labels.close')}}
        </a>
      </div>

      

      <!-- Transparent summary -->
      <!-- Summary -->
      <div id="summary" class="mobile-app-cancel-px py-4 gp-px-30 gp-sm-px-22 mt-18 mb-6 text-xl" style="--background-color: red;">
        <div class="text-semibold text-26 checkout-title">Checkout</div>

        <div class="text-medium text-15 deliver-to">{{ $t('labels.to') }}:</div>

        <div>
          <table class="text-semibold text-17 summary-table" style="line-height: 25px;"> 
            <tr v-if="$t('labels.IsDiscountEshop')=='1'">
              <td>{{ $t('labels.total') }}</td>
              <td>{{ prettyNum(totalLinesPrice2) }}</td>
            </tr>
            <tr v-if="$t('labels.IsDiscountEshop')=='1'" style="color:#7a7a7a">
              <!--<td>{{$t('labels.discount-eshop')}} ({{ totalLinesPrice2>=2000? "3+10%": "3%" }})</td>--><!--ΠΡΟΣΦΟΡΑ 12/2023-->
              <td>{{$t('labels.discount-eshop')}} (3%)</td>
              <td>{{ prettyNum(totalReward2) }}</td>
            </tr>
            <!--<tr style="color:#7a7a7a">
              <td>{{ $t('labels.cash-discount') }} ({{cashDiscountPerc}}%)</td>
              <td>{{ prettyNum(cashDiscountVal) }}</td>
            </tr>-->
            <tr>
              <td>{{ $t('labels.total-w-out-vat') }}</td>
              <td>{{ prettyNum(totalCost2) }}</td>
            </tr>
            <tr style="border-bottom:1px solid #212121">
              <td>{{ $t('labels.vat') }}</td>
              <td>{{ prettyNum(totalVat2) }}</td>
            </tr>
            <tr class="summary-total">
              <td>{{ $t('labels.total-w-vat') }}</td>
              <td>{{ prettyNum(totalCost2 + totalVat2) }}</td>
            </tr>
          </table>
        </div>

        <div class="text-15" style="line-height: 20px; font-weight: 300;">
          <span class="text-semibold">{{ accountDetails.customer_name }}</span><br/>
          {{ accountDetails.address }}<br/>
          {{ accountDetails.city }}<br/>
          {{ $t('labels.afm') }}: {{ accountDetails.vat_nr }}<br/>
          {{ $t('labels.tax-dept') }}: {{ accountDetails.tax_office }}<br/>
          <span v-if="accountDetails.phone && accountDetails.phone !== 'NULL' && accountDetails.phone !== ''">T: {{ accountDetails.phone }}<br/></span>
          E: {{ accountDetails.email }}
        </div>

        
        
      </div>

      <div class="text-right mb-2 mr-4-- gp-pr-30 gp-sm-pr-17" style=" margin-bottom:15px">
          <span class="border-b-1 border-white mx-auto cursor-pointer text-15 text-medium" @click="expandAll">
            {{ $t('labels.expand-all') }}
          </span>
      </div>

      <div class="rounded-xl border-1 border-21 mb-2" style="background-color: #f5f5e8;">

        <!-- items -->
        <div class="border-b-1 rounded-xl p-4-- space-y-4-- --mb-4 " :class="{expanded: true}" style="background-color: #f5f5e8; padding:10px">
          <div
              v-for="(item, index) in cartItems"
              :key="item.item_code"
              class="checkout-item px-4-xx py-4-xx border-1 border-21 rounded-xl --text-center text-lg font-semibold  cursor-pointer"
              style="padding-left:18px"
              :class="{hidden: index >= blocksize * loadedBlocks, expanded: isExpanded(item)}"
              @click="toggle(item)"
          >
            <div class="flex justify-between">
              <span class="flex-grow text-26 gp-sm-txt-20 gp-sm-lh-26 text-medium px-4-xx py-4" style="margin-bottom:0px">
                {{ item.item_description }}
              </span>
              
              <span class="gp-checkout-expand flex-shrink mr-8 text-15 text-medium gp-checkout-item-more px-4 py-4" style="" v-text="isExpanded(item) ? $t('labels.less') : $t('labels.more')"></span>
              
              <span style="padding:2px">
                <span 
                  class="hidden md:inline-block flex-shrink text-15 text-medium gp-checkout-item-remove" 
                  style="padding:0.9rem"
                  @click.stop="removeItem(item)">
                {{ $t('labels.remove') }}
              </span></span>
            </div>

            <transition name="slide-down-fast">
              <div v-if="isExpanded(item)" class="expanded-part" style="padding:0px 15px 15px">
                <checkout-cart-item-details
                    :item="item"
                    @add-package="addPackage"
                    @subtract-package="subtractPackage"
                />
              </div>
            </transition>
          </div>

          <!-- Load more & View all -->
          <div v-if="hiddenCartItems" class="mt-8">
            <button
                    class="app-button black w-full mb-2 text-15 text-medium"
                    style="line-height:15px; padding-top: 14px; padding-bottom: 14px; border:1px solid black"
                    @click="loadMore"
            >
              {{ $t('labels.load-more') }}
            </button>
            <div v-if="hiddenCartItems" class="text-right mb-2 gp-pr-20 gp-sm-pr-0" style="">
              <span class="border-b-1 border-white mx-auto cursor-pointer text-15 text-medium" @click="viewAll">
                {{ $t('labels.view-all') }}
              </span>
            </div>
          </div>
        </div>

        <!-- delivery address -->
        <div class="border-b-1 rounded-b-xl p-4-- gp-p-10 space-y-4 --mb-4" style="background-color: #efefe2;">
          <div class="pt-4 text-22" style="padding-left:20px">{{ $t('labels.delivery-address') }}</div>

          <div class="grid grid-cols-2 gap-4">
            <div
                v-for="address in addresses" :key="address.site_id"
                :class="{selected: addressId === address.site_id}"
                class="col-span-1 px-2 py-4 text-center font-semibold border-1 border-21 rounded-xl cursor-pointer flex justify-center items-center gp-delivery-address" style="background-color: #f5f5e8;"
                @click="addressId = address.site_id"
            >
              {{ address.address }}
            </div>
          </div>
        </div>

        <div class="border-b-1-- rounded-b-xl">
          <!-- method of shipping -->
          <div v-if="deliveryTypesLoaded" class="border-b-1-- rounded-b-xl-- p-4-- gp-p-10 space-y-4">
            <div class="pt-4 text-22" style="padding-left:20px">{{ $t('labels.method-of-shipping') }}</div>
            <div class="grid grid-cols-2 space-x-4-xx" style="gap:15px">
              <div
                  v-for="deliveryType in deliveryTypes" :key="deliveryType.id"
                  :class="{selected: deliveryTypeId === deliveryType.id}"
                  class="col-span-1 px-2 py-4 text-center font-semibold border-1 border-21 rounded-xl cursor-pointer gp-delivery-type" style="background-color: #f5f5e8;"
                  @click="deliveryTypeId = deliveryType.id"
              >
                {{ deliveryType.description }}
              </div>
            </div>
          </div>

          <!-- billing details -->
          <div v-if="paymentTypesLoaded" class="gp-p-10 space-y-4 tropos-pliromis">
            <div class="pt-4 text-22" style="padding-left:20px">{{ $t('labels.billing-details') }}</div>
            <div class="grid grid-cols-2 gap-4">
              <div
                  v-for="paymentType in paymentTypes" :key="paymentType.id"
                  :class="{selected: paymentTypeId === paymentType.id}"
                  class="col-span-1 px-2 py-4 text-center font-semibold border-1 border-21 rounded-xl cursor-pointer flex justify-center items-center gp-payment-type" style="background-color: #FFFBEF;"
                  @click="updatePaymentMethod(paymentType.id)"
              >
                {{ paymentType.description }}
              </div>
            </div>
          </div>

          <!-- order comments -->
          <div class="gp-p-10 space-y-4 form-control" style="">
            <div class="pt-4 text-22" style="padding-left:20px">{{ $t('labels.comments') }}</div>
            <textarea v-model="orderComment" type="text" 
            style="border:1px solid #212121; width:100%; padding:10px; font-size:15px; border-radius:10px; height:240px" />
          </div>

        </div>

      </div>

      <div
          v-if="accountDetails.vf_discount"
          class="rounded-xl border-1 border-21 p-4 mb-16-- text-15 text-medium btn-redeem-virtual-fasteners"
          style="background-color: rgb(245, 223, 165); line-height:15px; padding-top: 14px; padding-bottom: 14px; text-align:center; cursor:pointer; margin-bottom:45px"
          @click="toggleExtraDiscount"
      >
        <span v-if="useExtraDiscount">{{ $t('labels.undo-redeem') }} {{ accountDetails.virtual_fasteners }} virtual fasteners</span>
        <span v-else >{{ $t('labels.redeem') }} {{ accountDetails.virtual_fasteners }} virtual fasteners</span>
      </div>

      <!-- final price -->
      <div class="grid grid-cols-2 px-4 text-semibold manrope final-price" style="">
        <div class="col-span-1" style="padding-left:15px">{{ $t('labels.total-to-pay') }}</div>
        <div v-if="!useExtraDiscount" class="col-span-1 text-right">
          {{ prettyNum(totalCost + totalVat) }}
        </div>
        <div v-else class="col-span-1 text-right">
          <strike class="text-orange-600">{{ prettyNum(totalCost + totalVat) }}</strike> <span class="">{{ prettyNum((totalCost + totalVat) * (100 - accountDetails.vf_discount)/100) }}</span>
        </div>
      </div>

      <div class="">
        <button 
        class="app-button w-full gp-btn-checkout text-medium manrope"
        style="background-color: #142667; color: #fff; " 
        :style="{ background: submitBtnColor }"
        @click="sendOrder">
          {{ submitBtnCaption }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutCartItemDetails from "@/components/cart/CheckoutCartItemDetails";
export default {
  name: "ReviewCart",
  components: {CheckoutCartItemDetails},
  inject: ['enterFullscreen', 'exitFullscreen', 'openDialog', 'showNotification', 'prettyNum'],
  data() {
    return {
      blocksize: 9999,
      loadedBlocks: 1,
      addressId: null,
      paymentTypeId: 43,
      deliveryTypeId: null,
      expandedItems: [],
      // gray:  #7d7d7d
      // green: #5ad504
      // red: #E66000
      rewardColorNotation: '#7d7d7d',
      useExtraDiscount: false,
      orderComment: '',
      submitBtnEnabled: true
    }
  },
  computed: {
    dataLoaded() {
      return this.$store.getters['account/accountDataLoaded'];
    },
    account() {
      // console.log(this.$store.getters['account/businessDetails']);
      return this.$store.getters['account/businessDetails'];
    },
    accountDetails() {
      return this.$store.getters['account/accountDetails'];
    },
    addresses() {
      return this.$store.getters['account/addresses'];
    },
    cartItems() {
      return this.$store.getters['cart/items'];
    },
    totalCost() {
      return this.$store.getters['cart/totalCost'];
    },
    totalCost2() {
      return this.$store.getters['cart/totalCost2'];
    },
    totalReward() {
      return this.$store.getters['cart/totalReward']
    },
    totalReward2() {
      return this.$store.getters['cart/totalReward2']
    },

    cashDiscountPerc() {
      return this.$store.getters['cart/cashDiscountPerc']
    },
    cashDiscountVal() {
      return this.$store.getters['cart/cashDiscountVal']
    },
    totalVat() {
      return this.$store.getters['cart/totalVat']
    },
    totalVat2() {
      return this.$store.getters['cart/totalVat2']
    },
    eshopDiscount() {
      return this.$store.getters['cart/eshopDiscount']
    },
    totalLinesPrice() {
      return this.$store.getters['cart/totalLinesPrice']
    },
    totalLinesPrice2() {
      return this.$store.getters['cart/totalLinesPrice2']
    },
    hasItemsWithZeroInventory() {
      return this.$store.getters['cart/hasItemsWithZeroInventory']
    },

    hiddenCartItems() {
      return this.cartItems.length > this.blocksize*this.loadedBlocks;
    },
    deliveryTypes() {
      return this.$store.getters['cart/deliveryTypes'];
    },
    deliveryTypesLoaded() {
      return this.$store.getters['cart/deliveryTypesLoaded'];
    },
    paymentTypes() {
      return this.$store.getters['cart/paymentTypes'];
    },
    paymentTypesLoaded() {
      return this.$store.getters['cart/paymentTypesLoaded'];
    },
    submitBtnColor() {
      return this.submitBtnEnabled? "rgb(20,38,103)": "#ccc";
    },
    submitBtnCaption() {
      return this.submitBtnEnabled? this.$t('labels.checkout'): this.$t('labels.please-wait') 
    },
    is_seller() {
      return localStorage.getItem('sellertoken')===null? false: true;
    } ,
  },
  methods: {
    toggle(item) {
      if (this.isExpanded(item)) {
        this.collapse(item);
      } else {
        this.expand(item);
      }
    },
    isExpanded(item) {
      const itemIndex = this.expandedItems.findIndex(function(entry) {
        // console.log(entry, item.item_code);
        return entry === item.item_code
      });

      // console.log(itemIndex);

      return itemIndex !== -1;
    },
    expand(item) {
      // console.log('expanding item ', item);
      const itemIndex = this.expandedItems.findIndex(function(entry) {
        return entry === item.item_code
      });

      if (itemIndex === -1) {
        this.expandedItems.push(item.item_code);
      }
    },
    collapse(item) {
      // console.log('collapsing item ', item);
      const itemIndex = this.expandedItems.findIndex(function(entry) {
        return entry === item.item_code
      });

      if (itemIndex !== -1) {
        // this.expandedItems =
            this.expandedItems.splice(itemIndex, 1);
      }
    },
    expandAll() {
      this.expandedItems = this.cartItems.map(x => x.item_code);
    },
    loadMore() {
      this.loadedBlocks++;
    },
    viewAll() {
      this.loadedBlocks = Math.ceil(this.cartItems.length/this.blocksize);
    },
    decreaseAll() {
      this.loadedBlocks = 1;
    },
    async removeItem(item) {
      await this.$store.dispatch('cart/updateItems', {
        item_code: item.item_code,
        item_quantity: 0
      });
    },
    async addPackage(item) {
      await this.$store.dispatch('cart/addItems', [{
        item: item,
        item_code: item.item_code,
        item_quantity: item.main_package_quantity_pcs
      }]);

      this.rewardColorNotation = '#38d430';
    },
    async subtractPackage(item) {
      await this.$store.dispatch('cart/updateItems', {
        // item: item,
        item_code: item.item_code,
        // εγώ όπως και να έχει, στέλνω πάντα αριθμό τεμαχίων
        item_quantity: item.main_package_quantity_pcs * (item.quantity_packages - 1),
      });

      this.rewardColorNotation = '#ff5f00';
    },
    toggleExtraDiscount() {
      this.useExtraDiscount ? this.undoExtraDiscount() : this.applyExtraDiscount();
    },
    applyExtraDiscount() {
      this.useExtraDiscount = true;
    },
    undoExtraDiscount() {
      this.useExtraDiscount = false;
    },
    updatePaymentMethod(val) {
      //paymentTypeId = paymentType.id
      this.paymentTypeId = val;
      if (val==43) {
        /**metritois */
        this.loadCartData();
      }
      else {
        //pistosi
        this.loadCartDataNoCashDiscount();
      }
    },
    async loadAccountData() {
      try {
        await this.$store.dispatch('account/loadAccountData');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadCartData() {
      try {
        await this.$store.dispatch('cart/loadItems');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadCartDataNoCashDiscount() {
      try {
        await this.$store.dispatch('cart/loadItemsNoCashDiscount');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadDeliveryAndPaymentAndTypes() {
      try {
        await this.$store.dispatch('cart/loadDeliveryTypes');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }

      try {
        await this.$store.dispatch('cart/loadPaymentTypes');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async sendOrder() {
      
      let my_check = this.hasItemsWithZeroInventory;
      if (my_check) {
        this.openDialog({
          class: 'danger',
          html: 'Υπάρχουν είδη χωρίς απόθεμα.'
        });

        this.submitBtnEnabled = true;

        return;
      }

      if (!this.submitBtnEnabled) {
        return;
      }
      this.submitBtnEnabled = false;
      
      if (this.cartItems.length === 0) {
        this.openDialog({
          class: 'danger',
          html: 'Your cart is empty. \n' + 'Continue shopping'
        });

        this.submitBtnEnabled = true;

        return;
      }

      if (!this.addressId) {
        this.openDialog({
          class: 'danger',
          html: 'Δεν έχετε επιλέξει διεύθυνση παράδοσης.'
        });

        this.submitBtnEnabled = true;

        return;
      }

      

      if (!this.deliveryTypeId) {
        this.openDialog({
          class: 'danger',
          html: 'Δεν έχετε επιλέξει τρόπο παράδοσης.'
        });

        this.submitBtnEnabled = true;

        return;
      }

      if (!this.paymentTypeId) {
        this.openDialog({
          class: 'danger',
          html: 'Δεν έχετε επιλέξει τρόπο πληρωμής'
        });

        this.submitBtnEnabled = true;

        return;
      }

      //let my_check = this.has_items_with_zero_inventory();

      

      let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');
      //console.log(localStorage.getItem('sellerId'));
      console.log("sellertoken=" + sellertoken);
      
      try {
        await this.$store.dispatch('cart/checkout', {
          address_id: this.addressId,
          payment_type: this.paymentTypeId,
          shipping_type: this.deliveryTypeId,
          use_discount: this.useExtraDiscount,
          order_comment: this.orderComment,
          sellertoken: sellertoken,
        });

        // this.showNotification({
        //   class: 'default',
        //   title: 'Επιτυχία!',
        //   position: 'bottom-left',
        //   html: 'Η παραγγελία καταχωρήθηκε',
        //   lifeSpan: 2000,
        // });

        // redirect
        await this.$router.push('/order-success');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    }
  },
  created() {
    this.loadAccountData();
    this.loadCartData();
    this.loadDeliveryAndPaymentAndTypes();
  },
  beforeMount() {
    this.enterFullscreen();
  },
  beforeUnmount() {
    this.exitFullscreen();
  },
}
</script>

<style scoped>


  @media (max-width:440px) {
  .gap-4 {
    grid-template-columns:unset;
  }  
  .space-x-4 {
    grid-template-columns:unset;
    gap: 1rem;
  }
  .space-x-4 > :not([hidden]) ~ :not([hidden]) {margin-left:unset;}
  }

  .selected {
    border-color: #212121 !important;
    background-color: #212121 !important;
    color: #fff;
  }

  .gp-btn-redeem {
    cursor: pointer;
  }

  .gp-btn-redeem {
    font-size:15px;
  }

  .gp-payment-type, .gp-delivery-type, .gp-delivery-address {
    font-size: 15px;
  }

  /*.gp-text-22 {
    font-size:22px;
  }

  .gp-text-15 {
    font-size:15px;
  }

  .gp-text-26 {
    font-size:26px;
  }*/

  .gp-text-normal {
    font-weight:normal;
  }



  @media screen and (min-width: 800px) {
    #left-col {
      margin-top: 25px;
      margin-left: 50px;
    }

    #right-col {
      margin-right: 50px;
      margin-top: 25px;
      padding-top:0px;
    }

    .checkout-item:not(.expanded):hover,
    .checkout-item:not(.expanded):focus,
    .checkout-item:not(.expanded):active {
      background-color: #212121 !important;
      color: #fff;
    }
  }

 

  @media screen and (min-width: 800px) {
    #left-col {
      margin-top: 143px;
      margin-left: 50px;
    }

    #right-col {
      margin-right: 50px;
      margin-top: 35px;
    }
  }


  @media screen and (min-width: 1000px) {
    #left-col {
      margin-top: 143px;
      margin-left: 50px;
    }

    #right-col {
      margin-right: 50px;
      margin-top: 35px;
    }
  }



  @media screen and (min-width: 1200px) {
    #left-col {
      margin-top: 143px;
      margin-left: 100px;
    }

    #right-col {
      margin-right: 50px;
      margin-top: 35px;
    }
  }

  

  @media screen and (min-width: 1300px) {
    #left-col {
      margin-top: 143px;
      margin-left: 142px;
    }

    #right-col {
      margin-right: 100px;
      margin-top: 35px;
    }
  }


  .gp-checkout-item-remove {
    border-radius:10px;
    padding:0.8rem;
  }

  .gp-checkout-item-remove:hover {
    /*text-decoration: underline;*/
    color:#212121;
    background-color: #e6e5da;
  }


  .gp-checkout-item-more {
    opacity:0;
  }
  .checkout-item:hover .gp-checkout-item-more {
    opacity:1;    
  }
  .gp-checkout-item-more:hover {
    text-decoration: underline;
  }

  .gp-btn-checkout {
    font-size:15px;
    padding:29px 20px;
    margin-bottom:10px;
    margin-top:0px;
    
  }
  @media (max-width:800px) {
    .gp-btn-checkout {
      font-size:21px;
          
    }
  }
  
  
  .checkout-item {
    margin-top:0 !important;
    margin-bottom:10px !important;
  }


  .gp-checkout-expand {
      display:inline-block;
  }

  @media (max-width:800px) {
    .gp-checkout-expand {
      display:none;
    }
  }



.summary-labels {
  padding-left:25px;
  width:240px;
}

.summary-values {
  padding-right:10px;
}

.summary-values p {
  padding-left:80px;
  padding-right:0px;
}

@media (max-width:800px) {
.summary-labels {
  padding-left:25px;
  width:220px;
  float:left;
}

.summary-values {
  padding-right:0px;
}

.summary-values p {
  padding-left:20px;
  padding-right:0px;
  float:left;
  display:block;
  text-align:right;
  width:100px;
}  
}


div#summary {
  display:grid;
  grid-template-columns: 70% 30%;
  font-size:17px;
}
.checkout-title {
  margin-bottom:35px;
}
.deliver-to {
  margin-bottom:35px;
}
.summary-table {
  line-height: 25px; 
}
.summary-total {
  font-size:26px; line-height: 40px;
}

@media (max-width:800px) {
  div#summary {
    grid-template-columns: 100%;
    font-size:15px;
    margin-top:40px;
  }   
  .checkout-title {
    margin-bottom:15px;
  }
  .deliver-to {
    grid-row: 3;
    margin-top:30px;
    margin-bottom:10px;
  }
  .summary-table {
    line-height: 22px; width:100%;
  }
  .summary-total {
    font-size:22px; line-height: 30px;
  }
}

.discount-gray {
  color:#7d7d7d;
}

.total-lg-txt {
  font-size:26px;
  line-height:35px;
}

@media (max-width:1400px) {
.total-lg-txt {
  font-size:22px;
  line-height:28px;
}  
}

@media (max-width:800px) {
  

  .summary-cart-icon {
    width:75px;
    margin-bottom:20px;
  }

  .total-lg-txt {
    font-size:18px;
    line-height:26px;
  }



}





  .logo-container {
    position:absolute; left:96px; top:64px;
  }

  @media (max-width:1400px) {
  .logo-container {
    position:absolute; left:50px; top:37px; 
  }  
  }
@media not all and (min-resolution:.001dpcm) and (max-width:1400px) { 
     @supports (-webkit-appearance:none) {
      .logo-container {
        top:33px;
      }
     }
  }





  @media (max-width:1200px) {
  .logo-container {
    position:absolute; top:70px; left:30px;
  }  
  }

  @media (max-width:800px) {
  .logo-container {
    position:absolute; top:30px; left:0px;
  }  
  }


  .close-btn-container {
    height:40px;
    padding-right:30px;
  }

  @media (max-width:800px) {
   .close-btn-container {
      height:40px;
      padding-right:17px;
    } 
  }





  /** */
  .logo-container-abs {
    position:absolute; 
    top:64px; 
    left:96px;
    width:225px;
  }
  @media (max-width:1600px) {
    .logo-container-abs {
      top:42px; 
      left:96px
    }
  }
  @media (max-width:1400px) {
    .logo-container-abs {
      top:37px; 
      left:50px
    }
  }
  @media (max-width:1300px) {
    .logo-container-abs {
      top:36px; 
      left:50px;
      width:226px;
    }
    .gp-logo {
      width:226px;
    }
  }
  @media (max-width:1200px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }


  /** ipad 810*1080 - Landscape */
  @media (width:1080px) {
    .logo-container-abs {
      top:42px; 
      left:50px;
      width:210px;
    }
    .gp-logo {
      width:211px;
    }
  }

  @media (max-width:1079px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }

  @media (max-width:1000px) and (min-width:811px) {
    .logo-container-abs {
      top:30px; 
      left:49px;
      width:201px;
    }
    .gp-logo {
      width:201px;
    }
  }
  /** ipad 810*1080 Portrait */
  @media (width:810px) {
    .logo-container-abs {
      top:23px; 
      left:50px;
      width:200px;
    }
    .gp-logo {
      width:202px;
    }
  }
  

  @media (max-width:809px) {
    .logo-container-abs {
      top:60px; 
      left:20px;
      width:197px;
    }
    .gp-logo {
      width:197px;
    }    
  }

  @media (max-width:799px) {
    .logo-container-abs {
      display:none;
    }    
  }

  .btn-redeem-virtual-fasteners {
    display:none;
  }

  .tropos-pliromis {
    opacity:0;
    height:1px;
    overflow:hidden;
  }

  

  .summary-table td {
    padding:1px 15px 1px 0px;
  }

  .final-price {
    margin-bottom:20px; 
    padding-left:15px; 
    padding-right:30px;
    font-size: 26px;
    margin-top:50px;
  }
  @media (max-width:800px) {
    .final-price {
      margin-bottom:20px; 
      padding-left:0px; 
      padding-right:20px;
      font-size: 22px;
      line-height: 25px;
    }
  }

  @media (max-width:800px) {
    .logo-kg {
      display: block;
      top:35px;
      left:20px;
    }
  }

  @media (max-width:800px) {
    #left-col {
      margin-top:0px;
      height: 10px;
      padding: 0px;
    }
  }


</style>