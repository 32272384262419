<template>
  <footer id="footer" class="text-white pt-12 pb-6 mobile-app-px sm:px-16 text-sm manrope">

    <div class="grid-x grid-cols-12-x mb-16-- gp-mb-40 gp-sm-mb-70 footer-grid">
      <!-- column 1 -->
      <div v-if="lang_gr" class="flex flex-col space-y-4 mb-16-- gp-mb-20 text-15-sm-15 footer-col-1">
        <a class="text-base-- text-semibold" href="">
          ΠΑΡΑΓΓΕΛΙΑ
        </a>
        <!--<a class="text-light" href="">
          Categories
        </a>
        
        <a class="text-light" href="/account/rewards">
          Rewards
        </a>-->
        <a class="text-light" href="/account/my-profile">
          Λογαριασμός
        </a>
        <a class="text-light" target="_blank" href="https://tsamouris.gr/wp-contact/">
          Πεδίο υπαλλήλων
        </a>
        <!--<a v-if="auth" class="text-light" href="/offers">
          Offers
        </a>-->
      </div>

      <div v-else class="flex flex-col space-y-4 mb-16-- gp-mb-20 text-15-sm-15 footer-col-1">
        <a class="text-base-- text-semibold" href="">
          ORDER
        </a>
        <!--<a class="text-light" href="">
          Categories
        </a>
        
        <a class="text-light" href="/account/rewards">
          Rewards
        </a>-->
        <a class="text-light" href="/account/my-profile">
          Account
        </a>
        <a class="text-light" target="_blank" href="https://tsamouris.gr/wp-contact/">
          Πεδίο υπαλλήλων
        </a>
        <!--<a v-if="auth" class="text-light" href="/offers">
          Offers
        </a>-->
      </div>

      <!-- column 2 -->
      <div v-if="lang_gr" class="flex flex-col space-y-4 mb-16-- gp-mb-20 text-15-sm-15 footer-col-2">
        <a class="text-base-- text-semibold" href="">
          ΕΤΑΙΡΙΑ
        </a>
        <a class="text-light" href="/governance">
          Προφίλ
        </a>
        <a class="text-light" href="/about">
          Timeline
        </a>        
        <a class="text-light" href="/trust">
          Trust
        </a>
        <a class="text-light" href="/news">
          Newsroom
        </a>
        <a class="text-light" href="/contact">
          Contact
        </a>
      </div>

      <div v-else class="flex flex-col space-y-4 mb-16-- gp-mb-20 text-15-sm-15 footer-col-2">
        <a class="text-base-- text-semibold" href="">
          COMPANY
        </a>
        <a class="text-light" href="/en/governance">
          About
        </a>
        <a class="text-light" href="/en/about">
          Timeline
        </a>
        
        <a class="text-light" href="/en/trust">
          Trust
        </a>
        <a class="text-light" href="/en/news">
          Newsroom
        </a>
        <a class="text-light" href="/en/contact">
          Contact
        </a>
      </div>




      

      <!-- column 3 -->
      <div v-if="lang_gr" class="flex flex-col space-y-4 mb-16-- gp-mb-20 text-15-sm-15 footer-col-3">
        <a class="text-base-- text-semibold" href="">
          ΥΠΟΣΤΗΡΙΞΗ
        </a>
        <a class="text-light" href="/info-contact">
          Βοήθεια
        </a>
        
      </div>

      <div v-else class="flex flex-col space-y-4 mb-16-- gp-mb-20 text-15-sm-15 footer-col-3">
        <a class="text-base-- text-semibold" href="">
          SUPPORT
        </a>
        <a class="text-light" href="/en/info-contact">
          Help
        </a>
        
      </div>




    </div>

    <div class="grid grid-cols-12">
      <!-- lang -->
      <div class="col-span-6 sm:col-span-2 sm:order-3 sm:mb-0 mb-4 text-15-sm-13 text-light" 
        style="padding-top:3px">
        <a v-if="$store.getters['app/locale'] === 'el'"
           class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-footer" 
           :href="'/en' + $route.fullPath"
           :title="$t('labels.change-language')">
        
          <img src="../../assets/svg/globe-white.svg" class="h-4 mr-2">
          Ελληνικά
        </a>
        <a v-else
           class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-footer" 
           :href="'' + $route.fullPath"
           :title="$t('labels.change-language')">
        
          <img src="../../assets/svg/globe-white.svg" class="h-4 mr-2">
          English
        </a>
      </div>

      <!-- social -->
      <div class="col-span-6 sm:col-span-2 flex justify-end sm:justify-end sm:order-4 sm:mb-0 mb-4 space-x-4--">
        <a class="" target="_blank" href="https://tsamourisfast.medium.com/">
          <img src="../../assets/svg/medium.svg" class="medium-social-icon" style="">
        </a>
        <a class="" target="_blank" href="https://www.facebook.com/tsamourisfast/">
          <img src="../../assets/svg/facebook.svg" class="footer-social-icon" style="">
        </a>
        <a class="" target="_blank" href="https://www.instagram.com/tsamourisfast/">
          <img src="../../assets/svg/instagram.svg" class="footer-social-icon">
        </a>
        <a class="" target="_blank" href="https://twitter.com/tsamourisfast">
          <img src="../../assets/svg/X.svg" class="footer-social-icon">
        </a>
        <a target="_blank" class="" href="https://www.linkedin.com/company/65409864/admin/">
          <img src="../../assets/svg/linkedin.svg" class="footer-social-icon">
        </a>
        <a target="_blank" class="" href="https://www.youtube.com/channel/UCq0TOhBCyFIWlZoETbhdu_Q">
          <img src="../../assets/svg/youtube.svg" class="footer-social-icon">
        </a>
      </div>

      <!-- copyright -->
      <div class="col-span-12 sm:col-span-8 sm:order-1 sm:mb-0">
        <a class="text-15-sm-13 text-light" href="">
          &copy; {{ currentYear }} Tsamouris, 
          <a href="privacy">Privacy</a>, 
          <a href="terms">Terms</a> &nbsp; <!--| &nbsp;--> 
          <span style="font-size:13px; display:none">Design: Aris Pasouris, Code: BKH</span>
        </a>
      </div>

      <!-- design -->
      <!--<div class="col-span-6 sm:col-span-4 sm:order-2 sm:mb-0">
        <a class="text-15-sm-13 text-light" href="">
          Design: Aris Pasouris, Code: BKH
        </a>
      </div>-->
    </div>

  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
    auth() {
      return this.$store.getters['auth/auth'];
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    switchLocale() {
      // old way
      // this.$store.commit('app/switchLocale');
      // this.$i18n.locale = this.$store.getters['app/locale'];

      // new way
      if (this.$store.getters['app/locale'] === 'el') {
        return this.$router.push('en' + this.$route.fullPath);
      } else {
        return this.$router.push(this.$route.fullPath.replace('/en/', ''));
      }
    }
  },
  created() {

  }
}
</script>

<style scoped>
  footer {
    background-color: #000000;
  }

  @media (min-width: 800px) {
    footer {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  @media (min-width: 1200px) {
    footer {
      padding-left: 95px;
      padding-right: 95px;
    }
  }

  @media (min-width: 1400px) {
    footer {
      padding-left: 127px;
      padding-right: 95px;
    }
  }

  .footer-social-icon {
    height:48px;
    width:auto;
    margin-top:-15px;
  }

  @media (max-width:1400px) {
    .gp-lang-footer {
      padding-right:20px;
    }
  }

  .footer-social-icon {
    height:20px;
    margin:5px;
  }

  .footer-grid {
    display:grid;
    grid-template-columns:repeat(6,1fr);
    gap:30px;
  }

  .footer-col-2 {
    padding-left:20px;
  }

  @media (max-width:1200px) {
    .footer-grid {
      grid-template-columns:repeat(3,1fr);
    }
    .footer-col-2 {
      padding-left:0px;
    }
  }

  @media (max-width:600px) {
    .footer-grid {
      grid-template-columns:repeat(2,1fr);
    }
    .footer-col-1 {
      order:1;
    }
    .footer-col-3 {
      order:2;
    }
    .footer-col-2 {
      order:3;
    }
  }



</style>