import {createRouter, createWebHistory} from 'vue-router';

import HomePage from "./pages/HomePage";
import HowWeWork from "./pages/HowWeWork";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
// import DevCanvas from "./pages/misc/DevCanvas";
import Notfound from "./pages/errors/NotFound";
// import BlankCanvas from "./pages/misc/BlankCanvas";
import MobileFilters from "@/components/MobileFilters";
import AccountNavigation from "@/pages/account/AccountNavigation";
import MyProfile from "@/pages/account/profile/MyProfile";

import DownloadXml from "@/pages/account/DownloadXml";

import BusinessDetails from "@/pages/account/profile/BusinessDetails";
import BusinessAddresses from "@/pages/account/profile/BusinessAddresses";
import CategoryLandingPage from "@/pages/categories/CategoryLandingPage";
import LangWrapper from "@/pages/LangWrapper";
import RemoteContent1 from "@/pages/remote-content/RemoteContent1";
import RemoteContent3 from "@/pages/remote-content/RemoteContent3";
import RemoteContent2 from "@/pages/remote-content/RemoteContent2";

import RemoteContentAbout from "@/pages/remote-content/RemoteContentAbout";
import RemoteContentAbout2 from "@/pages/remote-content/RemoteContentAbout2";

import RemoteContentTerms from "@/pages/remote-content/RemoteContentTerms";
import RemoteContentPrivacy from "@/pages/remote-content/RemoteContentPrivacy";

import RemoteContentGovernance from "@/pages/remote-content/RemoteContentGovernance";
import RemoteContentTrust from "@/pages/remote-content/RemoteContentTrust";
import RemoteContentNews from "@/pages/remote-content/RemoteContentNews";
import RemoteContentArticle from "@/pages/remote-content/RemoteContentArticle";
import RemoteContentContact from "@/pages/remote-content/RemoteContentContact";
import RemoteContentOffer202302 from "@/pages/remote-content/RemoteContentOffer202302";
import RemoteContentOffer202303 from "@/pages/remote-content/RemoteContentOffer202303";
import RemoteContentOffer202304 from "@/pages/remote-content/RemoteContentOffer202304";
import RemoteContentOffer202305 from "@/pages/remote-content/RemoteContentOffer202305";
import RemoteContentOffers from "@/pages/remote-content/RemoteContentOffers";
import RemoteContentOffer202307KeysBahco from "@/pages/remote-content/RemoteContentOffer202307KeysBahco";
import RemoteContentOffer202308novopanovides from "@/pages/remote-content/RemoteContentOffer202308novopanovides";
import Offer202309bahcoChainsawSharpeners from "@/pages/remote-content/Offer202309bahcoChainsawSharpeners.vue";
import RemoteContentOffer202312 from "@/pages/remote-content/RemoteContentOffer202312.vue";
import RemoteContentOffer202401 from "@/pages/remote-content/RemoteContentOffer202401.vue";
import Offer202402 from "@/pages/remote-content/Offer202402.vue";
import RcIrimo from "@/pages/remote-content/RcIrimo.vue";

import BusinessAddressProfile from "@/pages/account/profile/business-addresses/BusinessAddressProfile";
import CategoryProducts from "@/pages/categories/CategoryProducts";

import CategorySections from "@/pages/categories/CategorySections";

import CategorySpecs from "@/pages/categories/ProductSpecs";
import InvoiceDetails from "@/pages/account/transactions/InvoiceDetails";
import InvoicesList from "@/pages/account/transactions/InvoicesList";
import TransactionsList from "@/pages/account/transactions/TransactionsList";
import TransactionsNav from "@/pages/account/transactions/TransactionsNav";
import SearchResults from "@/pages/SearchResults";
import CheckOut from "@/pages/cart/CheckOut";
import ReviewCart from "@/pages/cart/ReviewCart";
import SpeechSearch from "@/pages/SpeechSearch";

import store from './store/index.js';
import OrderSuccess from "@/pages/cart/OrderSuccess";
import MyRewards from "@/pages/account/MyRewards";
import MySavedItems from "@/pages/account/MySavedItems";
import RfqSuccess from "@/pages/cart/RfqSuccess";

import Seller from "@/pages/account/seller";

import InfoContact from "@/pages/info/InfoContact";
import InfoFindProduct from "@/pages/info/InfoFindProduct";
import InfoContactForm from "@/pages/info/InfoContactForm";
import InfoBigStock from "@/pages/info/InfoBigStock";
import InfoDiscount3 from "@/pages/info/InfoDiscount3";
import InfoHowToOrder from "@/pages/info/InfoHowToOrder";

import SearchToolCategories from "@/pages/SearchToolCategories";

import InstantOrderMain from "@/pages/InstantOrderMain";
import InstantOrder from "@/pages/InstantOrder";
import UploadCartItems from "@/pages/remote-content/UploadCartItems.vue";

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: 'how-we-work',
        component: HowWeWork,
    },
    {
        path: '/en',
        component: HomePage,
    },
    {
        path: 'login',
        component: SignIn,
        /*meta: {
            guest: true
        }*/
    },
    {
        path: 'register',
        component: SignUp,
        /*meta: {
            guest: true
        }*/
    },
    {
        path: 'filters',
        component: MobileFilters,
    },
    {
        path: 'search',
        component: SearchResults,
    },
    {
        path: 'search-tool-categories',
        component: SearchToolCategories,
    },
    {
        path: 'speech',
        component: SpeechSearch,
    },
    {
        path: 'category/:slug',
        props: true,
        component: CategoryLandingPage,
    },
    {
        path: 'product/:slug',
        props: true,
        component: CategoryProducts,
    },
    {
        path: 'section/:slug',
        props: true,
        component: CategorySections,
    },
    {
        path: 'product/:slug/specs',
        props: true,
        component: CategorySpecs,
    },
    {
        path: 'review-cart',
        props: true,
        component: ReviewCart,
        meta: {
            auth: true
        }
    },
    {
        path: 'checkout',
        props: true,
        component: CheckOut,
        meta: {
            auth: true
        }
    },
    {
        path: 'order-success',
        component: OrderSuccess,
        meta: {
            auth: true
        }
    },
    {
        path: 'rfq-success',
        component: RfqSuccess,
        meta: {
            auth: true
        }
    },
    {
        path: 'account',
        component: AccountNavigation,
        children: [
            {
                path: 'my-profile',
                component: MyProfile,
            },
            {
                path: 'my-profile/business-details',
                component: BusinessDetails,
            },
            {
                path: 'my-profile/business-addresses',
                component: BusinessAddresses,
            },
            {
                path: 'my-profile/business-addresses/:id',
                props: true,
                component: BusinessAddressProfile,
            },
            /*{
                path: 'my-profile/contact-details',
                component: ContactDetails,
            },*/
            {
                path: 'transactions',
                component: TransactionsNav,
            },
            {
                path: 'invoice/:id',
                props: true,
                component: InvoiceDetails,
            },
            {
                path: 'transactions/invoices',
                component: InvoicesList,
            },
            {
                path: 'transactions/invoices/:id',
                component: InvoiceDetails,
            },
            {
                path: 'transactions/list',
                component: TransactionsList,
            },
            {
                path: 'rewards',
                component: MyRewards,
            },
            {
                path: 'saved-items',
                component: MySavedItems,
            },
            {
                path: 'download-xml',
                component: DownloadXml,
            },
        ],
        meta: {
            auth: true
        }
    },
    {
        path: 'remote-content-1',
        component: RemoteContent1,
        // meta: {
        //     auth: true
        // }
    },
    {
        path: 'remote-content-2',
        component: RemoteContent2,
        // meta: {
        //     auth: true
        // }
    },
    {
        path: 'remote-content-3',
        component: RemoteContent3,
        // meta: {
        //     auth: true
        // }
    },
    {
        path: 'about',
        component: RemoteContentAbout,
        
    },
    {
        path: 'about2',
        component: RemoteContentAbout2,
        
    },
    {
        path: 'governance',
        component: RemoteContentGovernance,
        
    },
    {
        path: 'trust',
        component: RemoteContentTrust,
        
    },
    {
        path: 'news',
        component: RemoteContentNews,
        
    },
    {
        path: 'article/:slug',
        props: true,
        component: RemoteContentArticle,
        
    },
    {
        path: 'contact',
        component: RemoteContentContact,
        
    },
    {
        path: 'seller',
        component: Seller,
        
    },
    {
        path: 'info-contact',
        component: InfoContact,
        
    },
    {
        path: 'info-find-product',
        component: InfoFindProduct,
        
    },
    {
        path: 'info-contact-form',
        component: InfoContactForm,
        
    },
    {
        path: 'info-big-stock',
        component: InfoBigStock,
        
    },
    {
        path: 'info-discount3',
        component: InfoDiscount3,
        
    },
    {
        path: 'info-how-to-order',
        component: InfoHowToOrder,
        
    },

    {
        path: 'terms',
        component: RemoteContentTerms,
        
    },
    {
        path: 'privacy',
        component: RemoteContentPrivacy,
        
    },

    {
        path: 'offer202302',
        component: RemoteContentOffer202302,
        
    },
    {
        path: 'offer202303',
        component: RemoteContentOffer202303,
        
    },
    {
        path: 'offer202304',
        component: RemoteContentOffer202304,
        
    },
    {
        path: 'offer202305',
        component: RemoteContentOffer202305,
        
    },
    {
        path: 'offer202307keysbahco',
        component: RemoteContentOffer202307KeysBahco,
    },
    {
        path: 'offer202308novopanovides',
        component: RemoteContentOffer202308novopanovides,
    },
    //Offer202309bahcoChainsawSharpeners
    {
        path: 'offer-2023-09-bahco-chainsaw-sharpeners',
        component: Offer202309bahcoChainsawSharpeners,
    },

    {
        path: 'offer-2023-12',
        component: RemoteContentOffer202312,
    },

    {
        path: 'offer-2024-01-bahco-gazotanalies',
        component: RemoteContentOffer202401,
    },

    {
        path: 'offer-2024-02-bahco-screwdrivers',
        component: Offer202402,
    },
    {
        path: 'irimo-tools',
        component: RcIrimo,
    },

    
    {
        path: 'offers',
        component: RemoteContentOffers,
        
    },

    {
        path: 'instant-order-main',
        component: InstantOrderMain,
        
    },
    {
        path: 'instant-order',
        component: InstantOrder,
        
    },

    {
        path: 'upload-cart-items',
        component: UploadCartItems,
        
    },


    /*{
        path: 'dev',
        component: DevCanvas,
        meta: {
            auth: true
        }
    },*/
    // {
    //     path: 'dev/blank',
    //     component: BlankCanvas,
    //     // meta: {
    //     //     auth: true
    //     // }
    // },
];

/*
 * Set the initial (default) locale based on whether
 * the href includes /en/
 */
let base = '/';

if (window.location.href.includes('/en/') || window.location.href.endsWith('/en')) {
    base = '/en/';
}

const router = createRouter({
    base: base,
    mode: 'history',
    history: createWebHistory(base),
    routes: [
        {
            path: '/',
            component: LangWrapper,
            children: routes
        },
        {
            path: '/en',
            component: LangWrapper,
            children: routes
        },
        {
            // 404 page
            path: '/:notFound(.*)', // the (.*) means: "whatever is there next"
            component: Notfound,
        }
    ],
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
});

router.beforeEach(function(to, _, next) {
    if (to.meta.auth && !store.getters['auth/auth']) {
        if (to.path !== '/') {
            next('/login?redirect='+to.fullPath);
        } else {
            next('/login');
        }
    } else if (to.meta.guest && store.getters['auth/auth']) {
        next('/');
    } else {
        next();
    }
});

export default router;
