import ErrorUtil from "@/classes/ErrorUtil";

export default {
    namespaced: true,
    state() {
        return {
            items: [
                /*{
                    discount: "0.00"
                    discount_1: "0.00"
                    discount_2: "0.00"
                    final_price: "0.00"
                    item_code: "001003000800"
                    item_description: "EΞΑΓΩΝΕΣ DIN 933 ΙΝΟΧ Α2  3 X 8"
                    quantity_packages: 0
                    quantity_pcs: "12000.00"
                    total_price: "0.00"
                    unit_price: "0.00"
                    vat_percentage: "0.00"
                    vat_value: "0.00"
                }*/
            ],
            itemsLoaded: false,
            meta: {
                cash_discount_percentage: 0,
                cash_discount_value: 0,
                online_discount_value: 0,
                total_amount: 0,
                total_pcs: 0,
                total_price: 0,
                total_vat: 0,
                eshop_discount: 0,
                has_items_with_zero_inventory: false,
            },
            deliveryTypes: [],
            deliveryTypesLoaded: false,
            paymentTypes: [],
            paymentTypesLoaded: false,
        }
    },
    actions: {
        async loadItems(context) {
            // if (localStorage.getItem('cart')) {
            //     state.items = JSON.parse(localStorage.getItem('cart'));
            // }
            let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');

            await context.rootGetters['app/axios'].post('/cart', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    cash_discount: true,
                    // eshop_discount: localStorage.getItem('eshop_discount'),
                    eshop_discount: false,
                    sellertoken: sellertoken
                }
            })
            .then((response) => {
                if (response.data.result === 'error - customer not found') {
                    // way to call action from another vuex module!
                    context.dispatch('auth/logout', null, {root: true});
                    throw new Error('Your session has expired. Please log in.');
                }

                if (response.data.result !== 'success') {
                    throw new Error(response.data.result);
                }

                const items = response.data.data;
                const meta = response.data.meta;

                context.commit('updateCart', {
                    items: items,
                    meta: meta
                });
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();

                throw new Error(errorMessage);
            });
        },
        async loadItemsNoCashDiscount(context) {
            let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');
            await context.rootGetters['app/axios'].post('/cart', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    cash_discount: false,
                    // eshop_discount: localStorage.getItem('eshop_discount'),
                    eshop_discount: false,
                    sellertoken: sellertoken
                }
            })
            .then((response) => {
                if (response.data.result === 'error - customer not found') {
                    // way to call action from another vuex module!
                    context.dispatch('auth/logout', null, {root: true});
                    throw new Error('Your session has expired. Please log in.');
                }

                if (response.data.result !== 'success') {
                    throw new Error(response.data.result);
                }

                const items = response.data.data;
                const meta = response.data.meta;

                context.commit('updateCart', {
                    items: items,
                    meta: meta
                });
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();

                throw new Error(errorMessage);
            });
        },
        async addItems(context, items) {
            let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');
            await context.rootGetters['app/axios'].post('/cart/add', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    items: items,
                    // eshop_discount: localStorage.getItem('eshop_discount'),
                    eshop_discount: false,
                    sellertoken:sellertoken
                }
            })
            .then((response) => {
                const items = response.data.data;
                const meta = response.data.meta;

                context.commit('updateCart', {
                    items: items,
                    meta: meta
                });
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();

                throw new Error(errorMessage);
            });
        },
        
        async updateItems(context, payload) {
            let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');

            await context.rootGetters['app/axios'].post('/cart/update', {
                data: {
                    'accesstoken': context.rootGetters['auth/token'],
                    'lang': context.rootGetters['app/locale'],
                    'item_code': payload.item_code,
                    'item_quantity': payload.item_quantity,
                    //'eshop_discount': localStorage.getItem('eshop_discount'),
                    'eshop_discount': false,
                    sellertoken: sellertoken
                }
            })
            .then((response) => {
                const items = response.data.data;
                const meta = response.data.meta;

                context.commit('updateCart', {
                    items: items,
                    meta: meta
                });
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();

                throw new Error(errorMessage);
            });
        },
        async empty(context) {
            let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');
            await context.rootGetters['app/axios'].post('/cart/empty', {
                data: {
                    'accesstoken': context.rootGetters['auth/token'],
                    'lang': context.rootGetters['app/locale'],
                    sellertoken:sellertoken
                }
            })
            .then((response) => {
                const items = response.data.data;
                const meta = response.data.meta;

                context.commit('updateCart', {
                    items: items,
                    meta: meta
                });
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();

                throw new Error(errorMessage);
            });
        },
        async loadDeliveryTypes(context) {
            await context.rootGetters['app/axios'].get('/shipping-types', {
                query: {
                    'lang': context.rootGetters['app/locale'],
                }
            })
            .then((response) => {
                const deliveryTypes = response.data.data;

                context.commit('setDeliveryTypes', deliveryTypes);
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadPaymentTypes(context) {
            await context.rootGetters['app/axios'].get('/payment-types', {
                query: {
                    'lang': context.rootGetters['app/locale'],
                }
            })
            .then((response) => {
                const paymentTypes = response.data.data;

                context.commit('setPaymentTypes', paymentTypes);
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async checkout(context, payload) {
            await context.rootGetters['app/axios'].post('/checkout', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    address_id: payload.address_id,
                    payment_type: payload.payment_type,
                    shipping_type: payload.shipping_type,
                    use_discount: payload.use_discount,
                    order_comment: payload.order_comment,
                    sellertoken: payload.sellertoken,
                    // eshop_discount: localStorage.getItem('eshop_discount')
                    eshop_discount: false,
                }
            })
            .then((response) => {
                if (response.data.result !== 'success') {
                    throw new Error(response.data.result);
                }
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async rfq(context, payload) {
            let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');
            await context.rootGetters['app/axios'].post('/rfq', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    address_id: payload.address_id,
                    payment_type: payload.payment_type,
                    shipping_type: payload.shipping_type,
                    use_discount: payload.use_discount,
                    sellertoken:sellertoken
                }
            })
                .then((response) => {
                    if (response.data.result !== 'success') {
                        throw new Error(response.data.result);
                    }
                })
                .catch(function (error) {
                    const errorMessage = new ErrorUtil(error).composeMessage();
                    throw new Error(errorMessage);
                });
        },
    },
    mutations: {
        clearCache(state) {
            state.items = [];
            state.itemsLoaded = false;
        },
        updateCart(state, payload) {
            state.items = payload.items;
            state.meta = payload.meta;

            state.itemsLoaded = true;
            // console.log('updating cart .. (state.items, state.meta, payload)');
            // console.log(state.items, state.meta, payload);
        },
        // addItemToCart(state, payload) {
        //     const cartItemIndex = state.items.find(
        //         item =>
        //             item.itemId === payload.itemId
        //             &&
        //             item.itemType === payload.itemType
        //     );
        //
        //     if (cartItemIndex) {
        //         let quantity = payload.quantity !== undefined ? cartItemIndex.quantity + payload.quantity : 1;
        //
        //         cartItemIndex.quantity += quantity;
        //     } else {
        //         state.items.push(payload);
        //     }
        //
        //     localStorage.setItem('cart', JSON.stringify(state.items));
        // },
        // removeItemFromCart(state, payload) {
        //     const cartItemIndex = state.items.find(
        //         item =>
        //             item.itemId === payload.itemId
        //             &&
        //             item.itemType === payload.itemType
        //     );
        //
        //     if (cartItemIndex) {
        //         cartItemIndex.quantity--;
        //
        //         if (cartItemIndex.quantity === 0) {
        //             // remove item entry entirely
        //             state.items = state.items.filter(
        //                 item =>
        //                     item.itemId !== payload.itemId
        //                     &&
        //                     item.itemType !== payload.itemType
        //             );
        //         }
        //     }
        //
        //     localStorage.setItem('cart', JSON.stringify(state.items));
        // },
        setDeliveryTypes(state, payload) {
            state.deliveryTypes = payload;
            state.deliveryTypesLoaded = true;
        },
        setPaymentTypes(state, payload) {
            state.paymentTypes = payload;
            state.paymentTypesLoaded = true;
        },
    },
    getters: {
        cart(state) {
            return {
                items: state.items,
                totalCost: state.meta.total_price,
                reward: state.reward,
            };
        },
        items(state) {
            return state.items;
        },
        itemsLoaded(state) {
            return state.itemsLoaded;
        },
        totalAmount(state) {
            return state.meta.total_amount;
        },
        totalAmount2(state) {
            return state.meta.total_amount2;
        },
        totalCost(state) {
            return state.meta.total_price;
        },
        totalCost2(state) {
            return state.meta.total_price2;
        },
        totalReward(state) {
            return state.meta.online_discount_value;
        },
        totalReward2(state) {
            return state.meta.online_discount_value2;
        },
        eshopDiscount(state) {
            return state.meta.eshop_discount;
        },
        cashDiscountPerc(state) {
            return state.meta.cash_discount_percentage;
        },
        cashDiscountVal(state) {
            return state.meta.cash_discount_value;
        },
        totalVat(state) {
            return state.meta.total_vat;
        },
        totalVat2(state) {
            return state.meta.total_vat2;
        },
        totalLinesPrice(state) {
            return state.meta.total_lines_price;
        },
        totalLinesPrice2(state) {
            return state.meta.total_lines_price2;
        },
        deliveryTypes(state) {
            return state.deliveryTypes;
        },
        deliveryTypesLoaded(state) {
            return state.deliveryTypesLoaded;
        },
        paymentTypes(state) {
            return state.paymentTypes;
        },
        paymentTypesLoaded(state) {
            return state.deliveryTypes;
        },
        hasItemsWithZeroInventory(state) {
            return state.meta.has_items_with_zero_inventory;
        }
    },
}
