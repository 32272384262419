<template>
    <arrow-filters class="gp-arrow-filters-mb-"/>

    <div>
        <div class="page-header" :class="pageHeaderSectionClass">
            <div>
                <div>
                    <p>{{ topCategories.length }} Categories</p>
                    <h1 v-html="pageTitle"></h1>
                    <p>&nbsp;</p>
                </div>
                <div>
                    <p>&nbsp;</p>
                    <div v-html="pageSubtitle" style="margin-top:2px"></div>
                </div>
            </div>
            
        </div>

        <div class="page-content">
            <h2 v-html="pageTitle"></h2>

            <div class="categories-grid">
                <div v-for="category in topCategories" :key="category.id" class="category-tile">
                    <a :href="category.url">
                        <img :src="category.imageUrl" style="max-width:100%; max-height:200px;" />
                    </a>
                    <a :href="category.url">{{ category.name }}</a>
                    
                </div>
            </div>

        </div>

        <h2 v-if="lang_gr" class="other-sections-title">Δείτε τις άλλες προϊοντικές κατηγορίες</h2>
        <h2 v-else class="other-sections-title">Maybe you need also</h2>

        <!-- <div class="other-sections-grid">
            <div :class="otherSections.section1class">
                <a :href="otherSections.url1">{{ otherSections.title1 }}</a>
            </div>
            <div :class="otherSections.section2class">
                <a :href="otherSections.url2">{{ otherSections.title2 }}</a>
            </div>
        </div> -->

        <div class="other-sections-grid">
            <a :href="otherSections.url1" 
            :class="otherSections.section1class"
            class="new">
                <h3>{{ otherSections.title1 }}</h3>
            </a>
            <a :href="otherSections.url2" 
            :class="otherSections.section2class"
            class="new">
                <h3>{{ otherSections.title2 }}</h3>
            </a>
        </div>

        <div class="bottom-spacer"></div>

    </div>

    

    


</template>

<script>

    import ArrowFilters from "@/components/ArrowFilters";

    export default {
        name: "CategorySections",
        components: {
            ArrowFilters,
        },
        props: {
            slug: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                foo: '',
            }
        },
        methods: {
            async loadHomeCategories1() {
                try {
                    await this.$store.dispatch('categories/loadCategories', {
                    forceRefresh: true,
                    });
                } catch (error) {
                    console.log(error.message);
                }
             },
             async loadHomeCategories2() {
                try {
                    await this.$store.dispatch('categories/loadCategories2', {
                    forceRefresh: true,
                    });
                } catch (error) {
                    console.log(error.message);
                }
             },
             async loadHomeCategories3() {
                try {
                    await this.$store.dispatch('categories/loadCategories3', {
                    forceRefresh: true,
                    });
                } catch (error) {
                    console.log(error.message);
                }
             },
        },
        computed: {
            lang_gr() {
                return this.$store.getters['app/locale']=="el"
            },
            homeCategories1() {
                return this.$store.getters['categories/categories'];
            },
            homeCategories2() {
                return this.$store.getters['categories/categories2'];
            },
            homeCategories3() {
                return this.$store.getters['categories/categories3'];
            },
            pageTitle() {
                var myPageTitle = '';
                if (this.lang_gr) {
                    switch (this.slug) {
                        case 'fasteners':
                            myPageTitle = 'Βίδες';
                            break;

                        case 'handtools':
                            myPageTitle = 'Εργαλεία Χειρός';
                            break;

                        case 'powertools':
                            myPageTitle = 'Ηλεκτρικά Εργαλεία';
                            break;
                    
                        default:
                            break;
                    }
                }
                else {
                    switch (this.slug) {
                        case 'fasteners':
                            myPageTitle = 'Fasteners<br/>&nbsp;';
                            break;

                        case 'handtools':
                            myPageTitle = 'Hand Tools';
                            break;

                        case 'powertools':
                            myPageTitle = 'Power Tools';
                            break;
                    
                        default:
                            break;
                    }
                }
                
                return myPageTitle;
            },
            pageSubtitle() {
                var mySubtitle = '';
                if (this.lang_gr) {
                    switch (this.slug) {
                    case 'fasteners':
                    mySubtitle = 'Βίδες για τα πάντα, <br/>για παντού, για πάντοτε';
                    break;

                    case 'handtools':
                    mySubtitle = 'Αξιόπιστη πρόσφυση, <br/>κάθε στρίψιμο, κάθε στροφή.';
                    break;

                    case 'powertools':
                    mySubtitle = 'Δύναμη και αντοχή <br/>στα χέρια σου.';
                    break;
                
                    default:
                    break;
                    }
                }
                else {
                    switch (this.slug) {
                    case 'fasteners':
                    mySubtitle = 'Bolts for everything,<br/>for everywhere, for everytime.';
                    break;

                    case 'handtools':
                    mySubtitle = 'Reliable grip, <br/>every twist, every turn.';
                    break;

                    case 'powertools':
                    mySubtitle = 'Strength and endurance <br/>in your hands.';
                    break;
                
                    default:
                    break;
                    }
                }
                return mySubtitle;
            },
            topCategories() {
                let categories = null;
                switch (this.slug) {
                    case 'fasteners':
                    categories = this.homeCategories1;
                    break;

                    case 'handtools':
                    categories = this.homeCategories2;
                    break;

                    case 'powertools':
                    categories = this.homeCategories3;
                    break;
                
                    default:
                    break;
                }
                return categories;

            },
            pageHeaderSectionClass() {
                let myclass = '';
                switch (this.slug) {
                    case 'fasteners':
                    myclass = 'page-header-fasteners';
                    break;

                    case 'handtools':
                    myclass = 'page-header-handtools';
                    break;

                    case 'powertools':
                    myclass = 'page-header-powertools';
                    break;
                
                    default:
                    break;
                }
                return myclass;
            },
            otherSections() {
                let myOtherSections = null;
                switch (this.slug) {
                    case 'fasteners':
                    myOtherSections = {
                        section1class: 'other-section-handtools',
                        section2class: 'other-section-powertools',
                        title1: this.lang_gr? 'Εργαλεία Χειρός': 'Hand Tools',
                        title2: this.lang_gr? 'Ηλεκτρικά Εργαλεία': 'Power Tools',
                        url1: '/section/handtools',
                        url2: '/section/powertools',
                    }
                    break;

                    case 'handtools':
                    myOtherSections = {
                        section1class: 'other-section-fasteners',
                        section2class: 'other-section-powertools',
                        title1: this.lang_gr? 'Βίδες': 'Fasteners',
                        title2: this.lang_gr? 'Ηλεκτρικά Εργαλεία': 'Power Tools',
                        url1: '/section/fasteners',
                        url2: '/section/powertools',
                    }
                    break;

                    case 'powertools':
                    myOtherSections = {
                        section1class: 'other-section-fasteners',
                        section2class: 'other-section-handtools',
                        title1: this.lang_gr? 'Βίδες': 'Fasteners',
                        title2: this.lang_gr? 'Εργαλεία Χειρός': 'Hand Tools',
                        url1: '/section/fasteners',
                        url2: '/section/handtools',
                    }
                    break;
                
                    default:
                    break;
                }
                return myOtherSections;
            }

        },
        created() {
            this.loadHomeCategories1();
            this.loadHomeCategories2();
            this.loadHomeCategories3();
        }
    }

</script>

<style scoped>

    .page-header {
        background-size:cover;
        background-position:center;
        /*height:420px;*/        
    }
    @media (max-width:600px) {
        .page-header {
            margin-left:-20px;
            margin-top:30px;
            margin-right:-20px;
            background-position:top center;
        }
    }
    .page-header > div {
        margin-left:29%;
        display:grid;
        grid-template-columns: 30% 70%;
        height:420px;
    }
    @media (max-width:1500px) {
        .page-header > div {
            margin-left:20%;
            grid-template-columns: 35% 65%;
        }
    }
    @media (max-width:1150px) {
        .page-header > div {
            margin-left:50px;
            display:grid;
            grid-template-columns: 70% 30%;
            height:420px;
        }
    }
    @media (max-width:600px) {
        .page-header > div {
            margin-left:20px;
            grid-template-columns: 100%;
            height:460px;
            background-position: top center;
        }
    }
    .page-header > div > div:nth-child(1) {
        display:flex;
        flex-direction: column;
        justify-content: center;
        padding-right:280px;
    }
    @media (max-width:600px) {
        .page-header > div > div:nth-child(1) {
            padding-right:20px;
            padding-bottom:30px;
            justify-content: flex-end;
        }
    }

    .page-header p {
        white-space: nowrap;
    }
    
    .page-header > div > div:nth-child(1) p,
    .page-header > div > div:nth-child(2) p {
        font-size:17px;
        color:#fff;
    }
    .page-header > div > div:nth-child(1) h1 {
        font-size: 38px;
        line-height:45px;
        font-weight:600;
        color:#212121;
        white-space: nowrap;
    }
    
    .page-header > div > div:nth-child(2) {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left:200px;
        font-size:18px;
        color:#212121;
    }
    @media (max-width:1150px) {
        .page-header > div > div:nth-child(2) {
            padding-left:0px;
        }
    }
    @media (max-width:600px) {
        .page-header > div > div:nth-child(2) {
            display:none;
        }
    }


    .page-header-fasteners {
        background-image:url(../../assets/images/category-section-header-fasteners.jpg);
    }
    .page-header-handtools {
        background-image:url(../../assets/images/category-section-header-handtools.jpg);
    }
    .page-header-powertools {
        background-image:url(../../assets/images/category-section-header-powertools.jpg);
    }

    @media (max-width:600px) {
        .page-header-fasteners {
            background-image:url(../../assets/images/category-section-header-fasteners-mobile.jpg);
        }
        .page-header-handtools {
            background-image:url(../../assets/images/category-section-header-handtools-mobile.jpg);
        }
        .page-header-powertools {
            background-image:url(../../assets/images/category-section-header-powertools-mobile.jpg);
        }
    }

    .page-content {
        display:grid;
        grid-template-columns:28% 72%;
        padding:75px 70px;
        background-color:#fafafa;
    }
    @media (max-width:1500px) {
        .page-content {
            grid-template-columns:25% 75%;
        }
    }
    @media (max-width:1400px) {
        .page-content {
            grid-template-columns:25% 75%;
        }
    }
    @media (max-width:1000px) {
        .page-content {
            grid-template-columns:100%;
        }
        .page-content h2:nth-child(1) {
            display:none;
        }
    }
    @media (max-width:600px) {
        .page-content {
            padding:20px;
            margin-left:-20px;
            margin-right:-20px;
        }
    }

    .page-content h2 {
        font-size: 33px;
        font-weight:600;
        padding-left:50px;
    }

    .categories-grid {
        display:grid;
        grid-template-columns: repeat(6,1fr);
        gap:-1px;        
    }
    @media (max-width:1400px) {
        .categories-grid {
            grid-template-columns: repeat(4,1fr);
        }
    }
    @media (max-width:600px) {
        .categories-grid {
            grid-template-columns: repeat(2,1fr);
        }
    }
    .category-tile {
        border:1px solid #EFEFE2;
        background-color:#fff;
        border-radius: 10px;
        padding:20px;
        text-align: center;
        font-size: 14px;;
    }
    
    .category-tile:hover {
        z-index: 999;
        
        border: 1px solid transparent;
        border-radius: 10px;
        background-image: 
            linear-gradient(white, white),
            linear-gradient(90deg, #1415D0, #5907D0, #A18FFF);
            /* linear-gradient(90deg, #1415D0, #f00); */
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }

    h2.other-sections-title {
        text-align: center;
        font-size:33px;
        line-height:40px;
        font-weight:600;
        padding:50px 20px;
        background-color:#fafafa;
    }
    @media (max-width:600px) {
        h2.other-sections-title {
            margin-left:-20px;
            margin-right:-20px;
            font-size:24px;
            line-height:30px;
        }
    }

    

    .other-sections-grid {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap:7px;
        /* height:410px; */
        background-color:#fafafa;
        
    }
    @media (max-width:1400px) {
        .other-sections-grid {
            /* height:360px; */
        }
    }
    @media (max-width:600px) {
        .other-sections-grid {
            grid-template-columns: 100%;
            /* height:720px; */
            margin-left:-20px;
            margin-right:-20px;
        }
    }
    .other-sections-grid > div {
        /* background-size: 100%; */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position:relative;
        transition: all 0.5s;
    }

    .other-sections-grid > a.new {
        background-size: 100%;
        /* background-size: cover; */
        background-position: center;
        background-repeat: no-repeat;
        position:relative;
        transition: all 0.5s;
        aspect-ratio: 2.2708;
    }
    @media (max-width:1000px) {
        .other-sections-grid > a.new {
            aspect-ratio:unset;
            background-size: cover;
            height:400px;
        }
    }

    .other-sections-grid > a.new:hover {
        background-size: 110%;
    }


    /* .other-sections-grid > div:hover {
        background-size: 110%;
    } */
    .other-section-fasteners {
        background-image:url(../../assets/images/othersection-fasteners.jpg);
    }
    .other-section-handtools {
        background-image:url(../../assets/images/othersection-handtools.jpg);
    }
    .other-section-powertools {
        background-image:url(../../assets/images/othersection-powertools.jpg);
    }

    .other-sections-grid > div > a {
        display:block;
        position:absolute;
        bottom:40px;
        left:0px;
        right:0px;
        text-align: center;
        font-size: 30px;
    }

    .other-sections-grid > a.new > h3 {
        display:block;
        position:absolute;
        bottom:40px;
        left:0px;
        right:0px;
        text-align: center;
        font-size: 30px;
    }

    .bottom-spacer {
        height:120px;
        background-color:#fafafa;
    }
    @media (max-width:600px) {
        .bottom-spacer {
            margin-left:-20px;
            margin-right:-20px;
        }
    }




</style>