<template>

  <div class="add-to-cart-panel-container" id="add-to-cart-panel-container">
    <add-to-cart-panel2
        v-for="(localItem) in localItems" 
        :key="localItem.item_code"
        :product="localItem.item"
        :local-items="localItems"
    />
  </div>  
  

    <div v-if="!auth" id="please-login" class="bg-white p-8 border-1 ">
      <p class="mb-4 font-semibold">{{$t('messages.you-have-to-login')}}</p>
  
      <router-link class="app-button w-full block" :to="'/login?redirect='+$route.fullPath">
        {{ $t('labels.sign-in') }}
      </router-link>
    </div>

    <div v-if="localItems.length>0 && auth" class="text-xl flex product-totals">
        <div class="sm:p-4 flex flex-col justify-end">            
            <!-- Green box with the counter -->
            <div class="relative boxaki">
            <img src="../../assets/icons/green-box-outline.png" style="width: 84px; !important;"/>
            <span v-if="localItems.length > -90" style="top: 0; left: 28px; width:30px; padding:1px 4px; background-color: rgb(53, 211, 47)"
                    class="absolute text-center text-white text-14 font-semibold rounded-full px-1.5-- py-1-- w-6--"
            >{{ localItems.length }}</span>
            </div>
        </div>
        <div class="flex-grow"></div>
        <div class="product-subtotal flex flex-col justify-end p-6 sm:pt-8 text-semibold prdct-sbttl-frst" style="">
            <p class="">{{ $t('labels.total') }}</p>
            <p v-if="$t('labels.IsDiscountEshop')=='1'" class="" :style="{color: rewardColorNotation}">{{ $t('labels.reward') }}...</p>
            <p v-if="$t('labels.IsDiscountEshop')=='1'" class="">{{ $t('labels.total-final') }}</p>
        </div>
        <div class="product-subtotal flex flex-col justify-end p-6 sm:pt-8 text-semibold " style="min-width: 100px; max-width: 200px; ">
            <p class="text-right">{{ prettyNum(localItemsTotalCost) }}</p>
            <p v-if="$t('labels.IsDiscountEshop')=='1'" class="text-right" :style="{color: rewardColorNotation}">{{ prettyNum(reward) }}</p>
            <p v-if="$t('labels.IsDiscountEshop')=='1'" class="text-right">{{ prettyNum(localItemsTotalCost - reward) }}</p>
        </div>
    </div>

    <div v-if="localItems.length>0 && auth" class="flex flex-col space-y-4 p-8-xx products-add-to-cart" style="">
      <button v-if="!addingToCart" class="app-button w-full text-22 manrope text-medium" @click="addProductToCart">
        {{ $t('labels.add-to-order') }}
      </button>
      <div v-else style="text-align: center; width:100%; height:67px; ">
        <img  src="../../assets/svg/wait.svg" style="width:50px; height:50px; display:inline-block" />
      </div>
      
    </div>


</template>

<script>
import AddToCartPanel2 from "@/components/products/AddToCartPanel2";

export default {
  name: "ProductOrder",
  inject: ['showNotification', 'prettyNum', 'resetLocalItems'],
  components: {
    AddToCartPanel2
  },
  data() {
    return {
        rewardColorNotation: '#5ad504',
        addingToCart: false,
    }
  },
  props: {    
    localItems: {
      type: Array,
      required: true,
    },
    
  },
  computed: {
    reward() {
      return localStorage.getItem('eshop_discount')=="true"? this.localItemsTotalCost * 0.03: 0;
      //return 0;
    },
    localItemsTotalCost() {
      let sum = this.localItems.reduce(function(previousValue, currentValue) {
          return previousValue + currentValue.item.final_price2 * currentValue.item_quantity/currentValue.item.pcs_per_unit;
        }, 0);

      return sum;
      //return 0;
    },
    auth() {
      return this.$store.getters['auth/auth'];
    },
  },
  methods: {
    async addProductToCart() {
      if (this.addingToCart) {
        return;
      }
      
      if (this.localItems.length === 0) {
        return;
      }

      this.addingToCart = true;

      await this.$store.dispatch('cart/addItems', this.localItems);

     
      /*let my_msg_el = this.$store.getters['cart/totalLinesPrice2']>=2000? "Το προϊόν/τα προστέθηκε/αν στο καλάθι με επιτυχία. <br/>Συγχαρητήρια! Φτάσατε το όριο προσφοράς των 2.000 ευρώ! Θα λάβετε συνολική έκπτωση +10% και θα σας στείλουμε και το βαλιτσάκι δώρο Irimo.": "Το προϊόν/τα προστέθηκε/αν στο καλάθι με επιτυχία";

      let my_msg_en = this.$store.getters['cart/totalLinesPrice2']>=2000? "The product(s) were added to your order successfully. <br/>Congratulations! You have reached the offer limit of 2,000 euros! You will receive a total discount of +10% and we will also send you the Irimo gift bag.": "The product(s) were added to your order successfully";

      let my_notification_class = this.$store.getters['cart/totalLinesPrice2']>=2000? 'primary': 'default';

      let my_lifeSpan = this.$store.getters['cart/totalLinesPrice2']>=2000? 10000: 3000;*/

      
      let my_msg_el = "Το προϊόν/τα προστέθηκε/αν στο καλάθι με επιτυχία";
      let my_msg_en = "The product(s) were added to your order successfully";
      let my_notification_class = 'default';
      let my_lifeSpan = 3000;

      let my_lang = this.$store.getters['app/locale'];

      if (my_lang=='el') {
          this.showNotification({
            class: my_notification_class,
            title: 'Συγχαρητήρια!!!',
            position: 'bottom-left',
            html: my_msg_el,
            lifeSpan: my_lifeSpan,
          });
      }
      else {
          this.showNotification({
            class: my_notification_class,
            title: 'Congrats!',
            position: 'bottom-left',
            html: my_msg_en,
            lifeSpan: my_lifeSpan,
          });
      }

      this.reset();

      this.addingToCart = false;

      this.goto("app");

    },
    reset() {
      this.quantity = 0;
      // this.totalCost = 0;
      // this.reward = 0;
      this.resetLocalItems();
    },
    goto(elName, offset = 0) {
      let element = document.getElementById(elName);
      const getOffsetTop = element => {
        let offsetTop = 0;
        while(element) {
          offsetTop += element.offsetTop;
          element = element.offsetParent;
        }
        return offsetTop;
      }
      let top = getOffsetTop(element);
      window.scrollTo({ top: top - offset, behavior: 'smooth' });
    },
    
  }
}




  
  </script>

  <style scoped>

    @media screen and (max-width: 600px) {
    .prdct-sbttl-frst {
        padding-right: unset !important;}
    .product-subtotal {
        font-size:15px !important;
        line-height:20px !important;
    }
    /* .add-to-cart-panel-container {margin-left:16px; margin-right:16px;} */
    .product-totals  { position:relative;}
    .boxaki {top: -10px;
    left: 10px;}
    .boxaki > img {width: 73px !important;}
    .boxaki > span {left: 23px !important;}
    /* .products-add-to-cart  {margin-left:16px; margin-right:16px;} */

  .products-add-to-cart {
    background-image: url(../../assets/svg/octagon-bg-product-add-mob.svg) !important;
    /* background-size: 100% 100% !important; */
    margin-top: -40px !important;
    z-index: -1 !important;
    
  }

  

  .product-totals {
    background-image: url(../../assets/svg/octagon-bg-product-totals-mob.svg) !important;
    /* background-image: url(../../assets/svg/wht.svg); */
    background-position: bottom !important;
    background-size: 100% !important;
    background-color:unset !important;
    /* border:1px solid #212121; */
    border: none !important;
    border-top:2px solid #000 !important;
  }
  }


  @media screen and (max-width: 430px) {
    .app-button {font-size:18px !important;}
  }

  @media screen and (max-width: 375px) {
  .boxaki > img {width: 65px !important;}
  .boxaki > span {left: 19px !important;}
  .app-button {font-size:13px !important;}
  }

  .products-add-to-cart {
    background-image: url(../../assets/svg/octagon-bg-addtocart.svg);
    background-position: bottom;
    background-size: 100%;
    padding:10px;
    padding-top: 60px !important;
  }

  .products-add-to-cart button {
    border-radius: 14px !important;
  }

  .product-totals {
    background-image: url(../../assets/svg/octagon-bg-product-totals.svg);
    background-position: bottom;
    background-size: 100%;
    background-color:#f5f5e8;
    border:1px solid #212121;
    border-bottom: none;
  }

  .add-to-cart-panel-container {
    background-image: url(../../assets/images/add-to-cart-bg-black.png);
  }

  .product-subtotal {
      font-size:26px;
      line-height:30px;
      padding-top:15px;
      padding-bottom:15px;
  }
  
  @media (max-width:1400px) {
    .product-subtotal {
      font-size:20px;
      line-height:25px;
    }
  }

  #please-login  {
    border-radius: 15px;
  }

  </style>